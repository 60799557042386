
import { ROOT_ACTIONS } from "@/store/actions";
import { Component, Vue } from "vue-property-decorator";
import { CalendarEvent } from "@/types";
import { User } from "@/store/user/types";
import { USER_GETTERS } from "@/store/user/getters";
import { addDoc, collection, deleteDoc, doc, documentId, getDoc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { firestore, functions, storage } from "@/firebase";
import { format } from "date-fns";
import { fr, nlBE } from "date-fns/locale";
import { ref, uploadBytes } from "firebase/storage";
import { mixins } from "vue-class-component";
import { TipTap } from "@/mixins/TipTap";
import axios from "axios";

@Component({
  components: {
    RegisterWebinarParticipantDialog: () => import('@/components/dialogs/RegisterWebinarParticipantDialog.vue')
  }
})
export default class Details extends mixins(TipTap) {
  event: null | CalendarEvent = null;
  users: Record<string, User> = {};
  attendees = [] as { coach: string, present: boolean, user: string, addedActivity: boolean }[]

  loading = {
    communication: false
  }

  communicationInputs = {
    message: '',
    attachments: [] as File[]
  }

  onFileChange(files: File[]) {
    this.communicationInputs.attachments = files
  }

  dialogs = {
    registerParticipant: false,
  }

  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  async sendCommunication() {
    try {
      this.loading.communication = true;
      const attachments = [] as { name: string, id: string, type: string }[]

      for (const file of this.communicationInputs.attachments) {
        const id = (Math.random() + 1).toString(36).substring(2);
        attachments.push({
          name: file.name,
          type: file.type,
          id: `email-attachments/${id}`
        })
        const fileRef = ref(storage, `email-attachments/${id}`)
        await uploadBytes(fileRef, file)
      }

      const mails = Object.values(this.users).filter(user => user.type === 'PARTICIPANT').map(user => user.email)

      for (let m of mails) {
        await addDoc(collection(firestore, 'mails'), {
          attachments,
          message: this.communicationInputs.message,
          subject: this.event!.title,
          from: this.user.email,
          body: this.communicationInputs.message,
          to: [m],
          bcc: [],
        })
      }

      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'succsss',
        text: this.$t('communicationSent')
      })

      this.loading.communication = false;
    } catch (error: any) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error.message
      })
    }


    this.loading.communication = false;
  }

  async removeAttendee(user: string) {
    const attendee = await getDocs(query(collection(firestore, 'event-attendees'), where('user', '==', user)))
    if (attendee.docs.length === 0) return;

    await deleteDoc(attendee.docs[0].ref)
    this.fetchEvent();
  }

  async fetchEvent() {
    onSnapshot(doc(firestore, 'webinars', this.$route.params.id), async (fetchedEvent) => {
      if (fetchedEvent.exists()) {
        this.event = {
          id: fetchedEvent.id,
          ...fetchedEvent.data() as CalendarEvent
        }

        this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [
          { text: 'Webinars', target: '/admin/webinars' },
          { text: this.event?.title, target: `/admin/webinars/${this.event?.id}` }
        ])

        this.users[this.event.coach] = {
          ...(await getDoc(doc(firestore, 'users', this.event.coach))).data() as User,
          id: this.event.coach
        }
      }
      this.$forceUpdate()
    })

    const attendees = await getDocs(query(collection(firestore, 'event-attendees'), where('webinarId', '==', this.$route.params.id)))

    const toFetch = [] as string[]

    for (let attendee of attendees.docs) {
      if (!Object.keys(this.users).includes(attendee.data().user)) toFetch.push(attendee.data().user)
      if (!Object.keys(this.users).includes(attendee.data().coach)) toFetch.push(attendee.data().coach)
    }


    if (toFetch.length !== 0) {
      const fetchedUsers = await getDocs(collection(firestore, 'users'))

      for (let user of fetchedUsers.docs) {
        if (toFetch.includes(user.id)) {
          this.users[user.id] = {
            ...user.data(),
            id: user.id,
          } as User
        }
      }
    }

    this.attendees = attendees.docs.map(attendee => ({
      coach: attendee.data().coach,
      present: attendee.data().present,
      user: attendee.data().user,
      addedActivity: attendee.data().addedActivity
    }))
    this.$forceUpdate()

  }

  async mounted() {
    await this.fetchEvent();
  }

  formatDate(date: Date) {
    return format(date, 'dd/MM/yyyy HH:mm', {
      locale: this.user.language === 'nl-be' ? nlBE : fr
    })
  }

  getRandomDarkColor(): string {
    const randomColor = () => Math.floor(Math.random() * 256);
    const r = randomColor(), g = randomColor(), b = randomColor();
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luminance < 40 ? `rgb(${r},${g},${b})` : this.getRandomDarkColor();
  }

  async setPresence(user: string) {
    const attendee = await getDocs(query(collection(firestore, 'event-attendees'), where('user', '==', user), where('webinarId', '==', this.$route.params.id)))
    if (attendee.docs.length === 0) return;

    if(attendee.docs[0].data().actualActivityId) {
      await updateDoc(doc(firestore, 'actual-activities', attendee.docs[0].data().actualActivityId), {
        participantConfirmed: !attendee.docs[0].data().present
      })
    }

    await updateDoc(attendee.docs[0].ref, {
      present: !attendee.docs[0].data().present
    })
    

    // if (!this.attendees.find(attendee => attendee.user === user)?.addedActivity) {
    //   const programs = await getDocs(query(collection(firestore, 'programs'), where('participant', '==', user)))
    //   if (programs.docs.length === 0) return;
    //   let program = programs.docs[0].id as string

    //   const eventStart = this.event?.start as unknown as Date;

    //   const currentPhases = await getDocs(query(collection(firestore, 'phases'), where('start', '<=', eventStart), where('end', '>=', eventStart), where('programId', '==', program)))

    //   if (currentPhases.docs.length === 0) return;

    //   const activities = await getDocs(query(collection(firestore, 'activities'), where('phaseId', '==', currentPhases.docs[0].id)))
    //   for (let activity of activities.docs) {
    //     if (activity.data().toLowerCase().includes('webinar')) {
    //       await addDoc(collection(firestore, 'actual-activities'), {
    //         activityId: activity.id,
    //         actualHours: 2,
    //         comment: this.event?.title,
    //         date: this.event?.start,
    //         participantPresent: true,
    //         participantConfirmation: null,
    //       })
    //     }
    //   }
    // }

  }

  async copyTeamsUrlToClipboard() {
    await navigator.clipboard.writeText(this.event?.teamsUrl as string)
    this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
      color: 'success',
      text: this.$t('copiedToClipboard')
    })
  }
  get isAdmin() {
    return this.$store.getters[USER_GETTERS.ACTING_USER].type == 'ADMIN'
  }

  loadingSendSurvey = false;
  async sendSurvey() {
    this.loadingSendSurvey = true;
    await updateDoc(doc(firestore, 'webinars', this.event!.id!), {
      survey: true
    })
    await axios.get('https://sendwebinarsurveyondemand-k6l53ior4a-ew.a.run.app/')
    this.loadingSendSurvey = false;
  }
}

